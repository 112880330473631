const employees_routes = [
    {
    path: '/employees',
    name: 'employees',
    component: () =>
        import ('@/views/account/staf/employees/employees.vue'),
    beforeEnter(to, from, next) {
        if (
            localStorage.getItem('accessToken') &&
            (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
                JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
        ) {
            next()
        }
    },
    meta: {
        layout: 'content',
    },
}, 
    {
    path: '/employeeProfile/:id',
    name: 'employeeProfile',
    component: () =>
        import ('@/views/account/staf/employees/employeeProfile.vue'),
    beforeEnter(to, from, next) {
        if (
            localStorage.getItem('accessToken') &&
            (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
                JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
        ) {
            next()
        }
    },
    meta: {
        layout: 'content',
    },
}, 
]

export default employees_routes
