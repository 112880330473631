import Vue from 'vue'
import VueRouter from 'vue-router'
import customer_routes from './accoutant/customer'
import employees_routes from './accoutant/empoyees'
import expenses_routes from './accoutant/expenses'
import salaries_routes from './accoutant/salaries'
import service_routes from './accoutant/service'
import admin_routes from './admin/center'
/* eslint-disable */
Vue.use(VueRouter)

// import localStorage from '../defaultToken/defaultToken'
const routes = [
  // {
  //       path: '/',
  //       redirect: 'login',
  //   },

  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),

    meta: {
      layout: 'blank',
      reload: true,
    },
  },

  ...admin_routes,

  ...customer_routes,

  {
    path: '/anotherBillDetailsInvoice',
    name: 'anotherBillDetailsInvoice',
    component: () => import('@/views/account/bills/bill/invoice/print.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/anotherBillInvoice',
    name: 'anotherBillInvoice',
    component: () => import('@/views/account/bills/bill/print.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/anotherBills',
    name: 'anotherBills',
    component: () => import('@/views/account/bills/bill/show.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/deletedBills',
    name: 'deletedBills',
    component: () => import('@/views/account/bills/bill/deleted_bill/show.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        JSON.parse(localStorage.getItem('results')).account_type === 'manager'
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/editedBills',
    name: 'editedBills',
    component: () => import('@/views/account/bills/bill/edited_bill/show.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        JSON.parse(localStorage.getItem('results')).account_type === 'manager'
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/editedLog/:id/:name',
    name: 'editedLog',
    component: () => import('@/views/account/bills/bill/edited_bill/editedLog.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        JSON.parse(localStorage.getItem('results')).account_type === 'manager'
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  
  

  {
    path: '/anotherBillsDaily',
    name: 'anotherBillsDaily',
    component: () => import('@/views/account/bills/bill/daily_bill/show.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/anotherBills/showBills/id/:id/name/:name/phone/:phone',
    name: 'otherBillsShowBills',
    component: () => import('@/views/account/bills/bill/invoice/show.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //services
  ...service_routes,

  //expenses
  ...expenses_routes,

  {
    path: '/invoice/:id',
    name: 'invoice',
    component: () => import('@/views/account/bills/invoice.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  // actions
  {
    path: '/actions',
    name: 'actions-select',
    component: () => import('@/views/account/staf/actions/selectAction.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  // salaries
  ...salaries_routes,

  // employees
  ...employees_routes,

  // store
  {
    path: '/store/selling',
    name: 'storeSelling',
    component: () => import('@/views/account/store/storeSelling.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/store/selling/count',
    name: 'storeSellingCount',
    component: () => import('@/views/account/store/storeSellingCount.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/store/material',
    name: 'material',
    component: () => import('@/views/account/store/material.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/store/suppliers',
    name: 'suppliers',
    component: () => import('@/views/account/store/suppliers.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/account/reports/reports.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        JSON.parse(localStorage.getItem('results')).account_type === 'manager'
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //paymentRequired
  {
    path: '/paymentRequired',
    name: 'paymentRequired',
    component: () => import('@/views/paymentRequired/paymentRequired.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //setting
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting/setting.vue'),
    // beforeEnter(to, from, next) {
    //     if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
    //         next()
    //     } else {
    //         next('/')
    //     }
    // },
    meta: {
      layout: 'content',
    },
  },

  // {
  //   path: '*',
  //   redirect: 'error-404',
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})

export default router
