/* eslint-disable */

// const results = resultsLocalStorage.features_finger_id

import { mdiFileDocumentEdit } from '@mdi/js'

export default [
    //Bills
    {
        title: 'فواتير المبالغ الواردة',
        icon: mdiFileDocumentEdit,
        children: [{
                title: 'الفواتير اليومية',
                to: 'anotherBillsDaily',
                disabled: true,
                // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
            },
            {
                title: 'الفواتير',
                to: 'anotherBills',
                disabled: true,
                // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
            },
            {
                title: 'الزبائن',
                to: 'customers',
            },
            {
                title: 'ادارة الخدمات',
                to: {
                    name: 'services',
                },
            },
        ],
    },
]
