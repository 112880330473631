<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalAdminNav from '@/layouts/variants/content/horizontal-nav-accountant/LayoutContentHorizontalNav.vue'
import LayoutContentHorizontalCasherNav from '@/layouts/variants/content/horizontal-nav-casher/LayoutContentHorizontalNav.vue'
import LayoutContentHorizontalClerkNav from '@/layouts/variants/content/horizontal-nav-clerk/LayoutContentHorizontalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalAdminNav from '@/layouts/variants/content/vertical-nav-admin/LayoutContentVerticalNav.vue'
import LayoutContentVerticalCasherNav from '@/layouts/variants/content/vertical-nav-casher/LayoutContentVerticalNav.vue'
import LayoutContentVerticalClerkNav from '@/layouts/variants/content/vertical-nav-clerk/LayoutContentVerticalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'
import store from './store'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutContentHorizontalAdminNav,
    LayoutContentVerticalAdminNav,
    LayoutContentHorizontalCasherNav,
    LayoutContentHorizontalClerkNav,
    LayoutContentVerticalCasherNav,
    LayoutContentVerticalClerkNav,
    LayoutBlank,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    // onMounted(async () => {
    //   store.state.study_year = JSON.parse(localStorage.getItem('study_year'))

    //   // localStorage.setItem("studyYearData", JSON.stringify(studyYearData.value))
    // })

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') {
        const resultsLocalStorage = JSON.parse(localStorage.getItem('results'))

        const accountType = resultsLocalStorage.account_type

        if (accountType === 'manager') {
          return `layout-content-${appContentLayoutNav.value}-nav`
        }
        if (accountType === 'accountant') {
          return `layout-content-${appContentLayoutNav.value}-casher-nav`
        }
        if (accountType === 'clerk') {
          return `layout-content-${appContentLayoutNav.value}-clerk-nav`
        }
        if (accountType === 'admin') {
          return `layout-content-${appContentLayoutNav.value}-admin-nav`
        }
      }

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },

  created() {
    store.state.results = JSON.parse(localStorage.getItem('results'))
  },
}
</script>
