import axios from 'axios'
import sha512 from 'js-sha512'

class Api {
  async login(account_email, account_password, auth_phone_details, auth_ip, auth_city, auth_lat, auth_lon) {
    return axios
      .post('/login', {
        account_email,
        account_password: sha512(account_password),
        auth_phone_details,
        auth_ip,
        auth_city,
        auth_lat,
        auth_lon,

        // auth_phone_id: authData.firebaseToken,
        // auth_firebase: authData.firebaseToken,
      })
      .then(Response => ({
        error: false,
        results: Response,
      }))
      .catch(() => ({
        error: true,
        results: [],
      }))
  }

  // class School
  async getClassSchool() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get('/classSchool')
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  //Service
  async getService(type) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/services/type/${type}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addService(name, type) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/services/add`, {
        name,
        type,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editService(name, id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/services/edit`, {
        name,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeService(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/services/delete/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  //student Bills

  async studentSendNotification({ account_id, study_year, notificationBody }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/student/sendNotification`, {
        account_id,
        study_year,
        notificationBody,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // another bills
  async otherSalary({ isDeleted, isPaid, start_date, end_date, study_year, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/getAll`, {
        isDeleted,
        isPaid,
        start_date,
        end_date,
        study_year,
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getOneOtherBills({ isDeleted, isPaid, start_date, end_date, study_year, name }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/getAllInvoice`, {
        isDeleted,
        isPaid,
        start_date,
        end_date,
        study_year,
        name,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeOtherBills(name) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/otherSalary/deleteAll/name/${name}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addOtherBills({ name, date, payments, desc, mobile, service_type }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/addInvoice`, {
        name,
        date,
        payments,
        desc,
        mobile,
        service_type,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeOneOtherBills(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/otherSalary/deleteInvoice/id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAnotherPayment({ isDeleted, study_year, invoice_id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/getInvoice`, {
        isDeleted,
        study_year,
        invoice_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addAnotherPayment(payments, invoice_id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/addPayment`, {
        payments,
        invoice_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editAnotherPayment({ amount, type, desc, next_payment, id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/otherSalary/editInvoice`, {
        amount,
        type,
        desc,
        next_payment,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeAnotherPayment(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/otherSalary/deletePayment/id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  //salaries
  async getSalaries({ isDeleted, start_date, end_date, study_year, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/getSalary`, {
        isDeleted,
        start_date,
        end_date,
        study_year,
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAllSalaries({ isDeleted, start_date, end_date, study_year, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/getAllSalary`, {
        isDeleted,
        start_date,
        end_date,
        study_year,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getSingeSalaries({ isDeleted, study_year, search, salary_id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/getSingleSalary`, {
        salary_id,
        isDeleted,
        study_year,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getSalariesDiscounts({ isDeleted, start_date, end_date, study_year, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/getSalaryDiscounts`, {
        isDeleted,
        start_date,
        end_date,
        study_year,
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // student
  async getAllStudents() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .get('/student/getAll')
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // get student
  async getEmployee(study_year) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .get(`/counting/staff/getStaff/study_year/${study_year}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addEmployeeSalary({
    account_id,
    amount,
    discounts,
    additional,
    lectures_number,
    per_lectures_price,
    all_lectures_price,
    lectures_class_school,
    lectures_note,
    per_watch_price,
    watch_number,
    watch_note,
    study_year,
    payment_date,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/addSalary`, {
        account_id,
        amount,
        discounts,
        additional,
        lectures_number,
        per_lectures_price,
        all_lectures_price,
        lectures_class_school,
        lectures_note,
        per_watch_price,
        watch_number,
        watch_note,
        study_year,
        payment_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editEmployeeSalary({
    amount,
    discounts,
    additional,
    all_lectures_price,
    lectures_note,
    per_watch_price,
    watch_number,
    watch_note,
    payment_date,
    _id,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/staff/editSalary`, {
        amount,
        discounts,
        additional,
        all_lectures_price,
        lectures_note,
        per_watch_price,
        watch_number,
        watch_note,
        payment_date,
        _id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeEmployee(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .delete(`/counting/staff/delStaff/id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // reports
  async getReports(study_year, start_date, end_date) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/analysis/invoice`, {
        study_year,
        start_date,
        end_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getReportsExpenses(study_year, date, start_date, end_date) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/analysis/expenses`, {
        study_year,
        date,
        start_date,
        end_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getReportsSalary(study_year, start_date, end_date) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`counting/analysis/incomes`, {
        study_year,
        start_date,
        end_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // mainData
  async mainData() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .get(`/mainData`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async edit() {}
  async remove() {}
  async add() {}
}

export default new Api()
