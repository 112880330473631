const salaries_routes = [{
        path: '/salariesShow',
        name: 'salariesShow',
        component: () =>
            import ('@/views/account/staf/salaries/salariesShow.vue'),
        beforeEnter(to, from, next) {
            if (
                localStorage.getItem('accessToken') &&
                (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
                    JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
            ) {
                next()
            } else {
                next('/')
            }
        },
        meta: {
            layout: 'content',
        },
    },

    {
        path: '/salariesShowDiscounts',
        name: 'salariesShowDiscounts',
        component: () =>
            import ('@/views/account/staf/salaries/salariesShowDiscounts.vue'),
        beforeEnter(to, from, next) {
            if (
                localStorage.getItem('accessToken') &&
                (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
                    JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
            ) {
                next()
            } else {
                next('/')
            }
        },
        meta: {
            layout: 'content',
        },
    },

    {
        path: '/salariesShow/add',
        name: 'salariesShowAdd',
        component: () =>
            import ('@/views/account/staf/salaries/salariesAdd/salariesAdd.vue'),
        beforeEnter(to, from, next) {
            if (
                localStorage.getItem('accessToken') &&
                (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
                    JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
            ) {
                next()
            } else {
                next('/')
            }
        },
        meta: {
            layout: 'content',
        },
    },

    {
        path: '/salariesShow/edit',
        name: 'salariesShowEdit',
        component: () =>
            import ('@/views/account/staf/salaries/salariesEdit/salariesEdit.vue'),
        beforeEnter(to, from, next) {
            if (
                localStorage.getItem('accessToken') &&
                (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
                    JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
            ) {
                next()
            } else {
                next('/')
            }
        },
        meta: {
            layout: 'content',
        },
    },
]

export default salaries_routes
