/* eslint-disable */

// const results = resultsLocalStorage.features_finger_id

import { mdiFileDocumentEdit } from '@mdi/js'

export default [
  //Bills
  {
    title: 'المركز',
    icon: mdiFileDocumentEdit,
    to: 'center',
  },
]
