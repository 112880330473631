import preset from '@/@core/preset/preset'
import Vue from 'vue'
import '@fortawesome/fontawesome-free/css/all.css'

import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
    preset,
    icons: {
        iconfont: 'mdiSvg' || 'fa' || 'fa4',
    },
    theme: {
        options: {
            customProperties: true,
            variations: false,
        },
    },
})