const customer_routes = [
  // customers
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/account/bills/customers/customers.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountant' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'clerk')
      ) {
        next()
      } else {
        next('/')
      }
    },

    meta: {
      layout: 'content',
    },
  },
]

export default customer_routes
