const adminRoutes = [
  {
    path: '/center',
    name: 'center',
    component: () => import('@/views/admin/center/show.vue'),

    meta: {
      layout: 'content',
    },
  },

  {
    path: '/center/add',
    name: 'centerAdd',
    component: () => import('@/views/admin/center/add.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/center/edit/name/:name/id/:id',
    name: 'centerEdit',
    component: () => import('@/views/admin/center/edit.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/center/profile/name/:name/id/:id',
    name: 'centerProfile',
    component: () => import('@/views/admin/center/profile.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
]

export default adminRoutes
