/* eslint-disable */

// const results = resultsLocalStorage.features_finger_id

import { mdiCardAccountDetails } from '@mdi/js'

export default [
  {
    title: 'الزبائن',
    icon: mdiCardAccountDetails,
    to: 'customers',
  },
]
